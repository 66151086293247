<template>
   <NebulaGridRow :class="className" type="flex" v-if="title">
        <NebulaColumn class="empty-state__content" v-bind="columnSettings" :mdSize="8" :mdOffset="0" :smOffset="0">
            <div class="empty-state__image-area" v-if="imageSelection">
                <slot>
                    <img v-if="imageSelection" class="empty-state__image" :src="imageSelection.image" :alt="imageSelection.alt">
                </slot>
            </div>
            <div class="empty-state__text">
                <NebulaIcon v-if="muted" size="xxl" :symbol-id="icon" class="empty-state__icon" />
                <h2 class="empty-state__title">{{ title }}</h2>
                <p class="empty-state__description">{{ description }}</p>
                <NebulaButton
                    v-if="showButton && button"
                    :type="muted ? 'ghost' : 'fill'"
                    class="empty-state__button"
                    v-bind="button"
                />
            </div>
        </NebulaColumn>
    </NebulaGridRow>
</template>

<script>

import { NebulaButton, NebulaGrid, NebulaIcon } from '@discoveryedu/nebula-components';

const discoListening = require('@/assets/img-disco-listening.png');
const discoSearching = require('@/assets/img-disco-searching.png');
const discoCalendar = require('@/assets/img-disco-dog-calendar.png');

export default {
    name: 'EmptyState',
    components: {
        NebulaButton,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaIcon,
    },
    props: {
        button: Object,
        sectionTitle: String,
        icon: {
            type: String,
        },
        image: String,
        title: {
            type: String,
            required: true,
        },
        card: Boolean,
        description: {
            type: String,
            required: true,
        },
        showButton: {
            type: Boolean,
            default: true,
        },
        module: {
            type: Boolean,
            default: true,
        },
        muted: Boolean,
    },
    computed: {
        showButtonComputed() {
            // if necessary button props are not passed, default to false
            if (!this.buttonLink && !this.buttonText) {
                return false;
            }
            // otherwise, pass along the prop
            return this.showButton;
        },
        imageSelection() {
            const imageMap = {
                searching: {
                    image: discoSearching,
                    alt: 'Disco character searching',
                },
                calendar: {
                    image: discoCalendar,
                    alt: 'Disco character holding a calendar',
                },
                listening: {
                    image: discoListening,
                    alt: 'Disco Listening',
                },
            };

            if (this.image && Object.keys(imageMap).includes(this.image)) {
                return imageMap[this.image];
            }

            return null;
        },
        columnSettings() {
            if (this.module) {
                return {
                    columns: 12,
                    offset: 0,
                };
            }

            return {
                columns: 6,
                offset: 2,
            };
        },
        className() {
            return {
                'empty-state': true,
                'empty-state--card': this.card,
            };
        },
    },
    data() {
        return {
            discoCalendar,
            discoSearching,
        };
    },
};
</script>

<style lang='stylus'>
.empty-state {
    min-height: 200px;
    &__icon {
        fill: $nebula-color-platform-interface-400;
        margin-bottom: $nebula-space-2x;
    }

    &__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            margin-inline-end: $nebula-space-3x;
        }
    }

    &__image-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__image {
        max-height: 220px;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            margin-inline-end: $nebula-space-3x;
        }
    }

    &__title {
        nebula-text-responsive-h6();
        margin: 0 0 $nebula-space-half;
    }

    &__description {
        nebula-text-responsive-body();
        margin: 0;
        font-weight: $nebula-font-weight-body;
    }

    &__text {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__button {
        margin-block-start: $nebula-space-2x;
    }

    &--card {
        padded-card();
    }
}
</style>
