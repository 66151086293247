<template>
    <NebulaTable
        class="request-directory-table"
    >
        <template v-slot:table>
            <table class="nebula-table">
                <thead class="nebula-table__head">
                    <tr class="nebula-table__row">
                        <NebulaTableHeader
                            v-for="(header, idx) in headers"
                            :key="idx"
                            :text="header"
                        />
                    </tr>
                </thead>
                <tbody>
                    <slot />
                </tbody>
            </table>
        </template>
    </NebulaTable>
</template>

<script>
import { NebulaTable, NebulaTableHeader } from '@discoveryedu/nebula-components';

export default {
    name: 'MemberTable',
    components: {
        NebulaTable,
        NebulaTableHeader,
    },
    props: {
        headers: {
            type: Array,
            required: true,
        },
    },
};
</script>
