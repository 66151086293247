<template>
    <GrayBoxLoader cardRow v-if="loading" />
    <ul v-else class="stat-cards stat-cards--admin">
        <StatCard v-for="card in cards" :key="`stat-card-${card.id}`" :data="card" />
    </ul>
</template>

<script>
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import stats from '@/data/content/stats';
import { get } from '@/utils/fetches';
import createPayload from '@/mixins/data/createPayload';

import StatCard from '@/components/shared/cards/StatCard.vue';

export default {
    name: 'AdminStats',
    mixins: [createPayload],
    components: {
        StatCard,
        GrayBoxLoader,
    },
    computed: {
        appMode() {
            return this.$store.state.app.mode;
        },
        flags() {
            return this.$store.getters.flags;
        },
    },
    data() {
        return {
            loading: true,
            cards: [],
        };
    },
    methods: {
        async fetchStats() {
            const payload = await this.createPayload();
            this.loading = true;
            const statTypes = Object.keys(stats.deAdminStatsMap);
            let data = {};

            await Promise.all([
                get(payload, '/requests/stats'),
                get(payload, '/educators/stats'),
                get(payload, '/employees/stats'),
            ]).then((responses) => {
                responses.forEach((response) => {
                    data = { ...data, ...response.data };
                });
            });

            statTypes.forEach((type, idx) => {
                const { title, popover, icon } = stats.deAdminStatsMap[type];
                const cardData = {
                    id: idx,
                    title,
                    popover,
                    icon,
                    stat: data[type],
                };

                this.cards.push(cardData);
            });
            this.loading = false;
        },
    },
    mounted() {
        this.fetchStats();
    },
};
</script>

<style lang='stylus'>
.stat-cards {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0;
    width: 100%;

    @media (max-width: "calc(%s - 1px)" % $nebula-breakpoints-tablet-portrait) {
        background-color: $nebula-color-platform-white;
        border-radius: $nebula-border-radius-small;
        box-shadow: $nebula-shadow-100;
        padding: 0 $nebula-space-3x $nebula-space-1x;
    }

    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        gap: $nebula-space-2x;
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $nebula-breakpoints-desktop) {
        grid-template-columns: repeat(4, 1fr);
    }

    &--admin {
        margin-block-start: $nebula-space-2x;
        margin-block-end: $nebula-space-4x;
    }
}
</style>
