const subjectIconMap = {
    biology: 'science-flask',
    chemistry: 'science-flask',
    science: 'science-flask',
    socialstudies: 'social-studies',
    math: 'math',
    stem: 'stem-connect',
    coding: 'coding',
    business: 'activity',
    'computer-science': 'coding',
    data: 'activity',
    'earth-science': 'earth-and-space-science',
    engineering: 'stem-connect',
    english: 'book',
    financial: 'activity',
    general: 'short-answer',
    health: 'health',
    government: 'social-studies',
    mathematics: 'math',
    'physical-science': 'physical-science',
    'social-studies': 'social-studies',
    'visual-arts': 'paint-brush',
    'life-science': 'science-flask',
};

export default subjectIconMap;
