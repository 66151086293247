<template>
    <div>
        <h2 v-if="header" class="profile-form-section__header">{{ header }}</h2>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ProfileFormSection',
    props: {
        header: {
            type: String,
        },
    },
};
</script>

<style lang="stylus">
.profile-form-section__header {
    nebula-text-responsive-h6();
}
</style>
