<template>
    <NebulaPageWrapper contained padded class="admin-request-management">
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <PageHeader title="Connect Directory" />
                <AdminStats />
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" class="admin-request-management__filter-row">
            <NebulaColumn :columns="12" :mdSize="8" class="admin-request-management__tabs">
                <NebulaTabs
                    :aria-label="$t('link-navigation-aria', { ns: 'requestdirectory' })"
                    class="admin-request-management__tabs-navigation"
                    useFlatButton
                    noBorderBottom
                >
                    <template v-slot:tabs>
                        <NebulaTabItem
                            v-for="(tab, index) in tabs"
                            :ref="tabMap[tab.name].value"
                            :key="index"
                            :tab="tab"
                            :selected="tabActive[1] === index"
                            @click.native="applyTab(tab.name, index)"
                        />
                    </template>
                </NebulaTabs>
            </NebulaColumn>
            <NebulaColumn :columns="6" :mdSize="8" class="admin-request-management__filters">
                <FilterPopover>
                    <FilterList
                        :items="tagOptions"
                        topic="subject"
                        title="Subject Area"
                        :selected="filters.subject"
                        @checkbox-toggled="handleFilter"
                    />
                    <FilterList
                        :items="requestStatusOptions"
                        :selected="filters.status"
                        topic="status"
                        title="Status"
                        @checkbox-toggled="handleFilter"
                    />
                    <FilterList
                        :items="typeOptions"
                        :selected="filters.type"
                        topic="type"
                        title="Type"
                        @checkbox-toggled="handleFilter"
                    />
                </FilterPopover>
                <SortDropdown :options="sortOptions" :handleFunction="handleSort" />
            </NebulaColumn>
        </NebulaGridRow>
        {{ currentMonthDisplay }} {{ currentYearDisplay }}
        <NebulaGridRow v-if="results && results.length > 0" type="flex">
            <NebulaColumn :columns="12">
                <RequestDirectoryTable
                    class="admin-request-management__list-view"
                    :data="results"
                    mode="directory"
                />
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" v-else>
            <GrayBoxLoader card v-if="loading" />
            <NebulaColumn v-else :columns="12">
                <EmptySearch />
            </NebulaColumn>
        </NebulaGridRow>
        <PaginationRow
            v-if="paging"
            :paging="paging"
            :pagination="pagination"
            :loading="loading"
            :resultsLength="results.length"
            :updatePagination="updatePagination"
            @select-page="getResults"
        />
    </NebulaPageWrapper>
</template>

<script>
import axios from 'axios';
import {
    NebulaGrid,
    NebulaTabs,
    NebulaTabItem,
} from '@discoveryedu/nebula-components';
import createPayload from '@/mixins/data/createPayload';
// import StatCard from '@/components/admin/StatCard.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import SortDropdown from '@/components/browse/SortDropdown.vue';
import FilterPopover from '@/components/shared/tokens/FilterPopover.vue';
import FilterList from '@/components/shared/tokens/FilterList.vue';
import EmptySearch from '@/components/browse/EmptySearch.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import PaginationRow from '@/components/browse/PaginationRow.vue';
import RequestDirectoryTable from '@/components/shared/RequestDirectoryTable.vue';
import AdminStats from '@/components/admin/AdminStats.vue';

import { browse, pagination } from '@/mixins/browse';
import scrollToTop from '@/mixins/scrollToTop';
import {
    monthMap,
    offsetMonth,
    thisWeek,
    thisMonth,
} from '@/data/placeholder/date';

import {
    tagOptions,
    requestStatusOptions,
    timeSlotOptions,
    typeOptions,
} from '@/data/formData';
import {
    actionRequired,
    completed,
    futureStatuses,
} from '@/constants';

const API_DOMAIN = process.env.VUE_APP_API_URL;

export default {
    name: 'RequestManagement',
    mixins: [createPayload, pagination, browse, scrollToTop],
    components: {
        // StatCard,
        PageHeader,
        SortDropdown,
        FilterPopover,
        FilterList,
        EmptySearch,
        GrayBoxLoader,
        PaginationRow,
        RequestDirectoryTable,
        NebulaTabs,
        NebulaTabItem,
        AdminStats,
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
    },
    computed: {
        tagOptions,
        timeSlotOptions,
        results() {
            return this.$store.state.page.requests;
        },
    },
    data() {
        return {
            loading: true,
            requestStatusOptions,
            typeOptions,
            monthSetting: 0,
            currentMonthDisplay: null,
            currentYearDisplay: null,
            statCards: [],
            tabs: [
                {
                    name: 'This week',
                },
                {
                    name: 'Previous month',
                },
                {
                    name: 'This month',
                },
                {
                    name: 'Next month',
                },
                {
                    name: 'Action Required',
                },
                {
                    name: 'Completed',
                },
            ],
            primaryTabs: [],
            tabActive: {
                1: null,
                2: null,
            },
            tabMap: {
                'This week': {
                    value: 'week',
                },
                'This month': {
                    value: 'month',
                },
                'Previous month': {
                    value: 'prev-month',
                },
                'Next month': {
                    value: 'next-month',
                },
                'Action Required': {
                    value: actionRequired,
                },
                Completed: {
                    value: completed,
                },
            },
            filters: {
                startDate: [],
                endDate: [],
                status: [],
                subject: [],
                type: [],
            },
            sortOptions: [
                {
                    value: 'upcoming',
                    label: 'Upcoming (default)',
                },
                {
                    value: 'type-ascending',
                    label: 'Type (A to Z)',
                },
                {
                    value: 'type-descending',
                    label: 'Type (Z to A)',
                },
                {
                    value: 'subject-ascending',
                    label: 'Subject Area (A to Z)',
                },
                {
                    value: 'subject-descending',
                    label: 'Subject Area (Z to A)',
                },
            ],
            sort: 'upcoming',
            sortConfig: {
                upcoming: {
                    sort: 'startDate',
                    order: 'ASC',
                },
                'type-ascending': {
                    sort: 'type',
                    order: 'ASC',
                },
                'type-descending': {
                    sort: 'type',
                    order: 'DESC',
                },
                'subject-ascending': {
                    sort: 'subject',
                    order: 'ASC',
                },
                'subject-descending': {
                    sort: 'subject',
                    order: 'DESC',
                },
            },
            employees: {},
            educators: {},
            request_stats: {},
            requests: {},
        };
    },
    methods: {
        async getAllRequests() {
            const payload = await this.createPayload();

            const response = await axios.get(`${API_DOMAIN}/requests`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                    },
                });
            this.requests = response.data;
        },
        async applyTab(name, idx, level = 1) {
            const appliedTabValue = this.tabMap[name].value;
            const timeNavTabs = ['next-month', 'prev-month', 'month'];
            if (!timeNavTabs.includes(appliedTabValue) && this.tabActive[level] === idx) {
                this.applyDefaultFilters();
                this.tabActive[level] = null;
                this.fetchAction = 'getRequests';
                this.getResults();
                return;
            }

            const timeTabs = ['week', 'month', 'prev-month', 'next-month'];
            const statusTabs = [actionRequired, completed];

            if (timeTabs.includes(appliedTabValue)) {
                let params = thisWeek();

                if (appliedTabValue.includes('month')) {
                    switch (appliedTabValue) {
                    case 'prev-month':
                        this.monthSetting -= 1;
                        break;
                    case 'next-month':
                        this.monthSetting += 1;
                        break;
                    default:
                        this.monthSetting = 0;
                        break;
                    }
                    params = thisMonth(this.monthSetting);
                    const { month, year } = offsetMonth(this.monthSetting);
                    this.currentMonthDisplay = monthMap[month];
                    this.currentYearDisplay = year;

                    const { month: nextMonth, year: nextYear } = offsetMonth(this.monthSetting + 1);
                    const nextMonthEl = this.$refs['next-month'][0].$el;
                    nextMonthEl.querySelector('.nebula-button__text').innerText = `${monthMap[nextMonth]} ${nextYear}`;

                    const { month: prevMonth, year: prevYear } = offsetMonth(this.monthSetting - 1);
                    const prevMonthEl = this.$refs['prev-month'][0].$el;
                    prevMonthEl.querySelector('.nebula-button__text').innerText = `${monthMap[prevMonth]} ${prevYear}`;
                }

                const { startDate, endDate } = params;
                this.filters.startDate = [startDate];
                this.filters.endDate = [endDate];
            }

            if (statusTabs.includes(appliedTabValue)) {
                this.filters.status = [appliedTabValue];
            }

            if (!timeNavTabs.includes(appliedTabValue)) {
                this.tabActive[level] = idx;
            }
            this.getResults();
        },
        async getResults() {
            this.loading = true;
            this.scrollToTop();
            const params = this.getCombinedParams();

            const payload = await this.createPayload({ params });
            await this.$store.dispatch('getRequests', payload);

            this.loading = false;
        },
        applyDefaultFilters() {
            this.filters.startDate = [];
            this.filters.endDate = [];
            this.filters.status = [];
            this.filters.subject = [];
            this.filters.type = [];

            futureStatuses.forEach((statusOption) => {
                this.updateFilter({
                    topic: 'status',
                    value: statusOption,
                });
            });
        },
    },
    async mounted() {
        this.scrollToTop();
        this.applyPageSettings();
        this.loading = true;

        await this.getResults();
        this.initializePagination();
    },
};
</script>

<style lang="stylus">
.admin-request-management {
    &__tabs, &__filters {
        display: flex;
    }

    &__tabs {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            justify-content: flex-start;
        }
    }

    &__tabs-primary {
        border-bottom: 1px solid $nebula-color-platform-interactive-300;
    }

    &__filters {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            justify-content: flex-end;
        }
    }

    &__filter-row {
        gap: $nebula-space-2x;
        display: flex;
        align-items: center;
        margin-bottom: $nebula-space-4x;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            justify-content: flex-end;
            margin-bottom: $nebula-space-5x;
        }
    }
}
</style>
