<template>
    <NebulaPageWrapper contained padded>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <NebulaBreadcrumbs
                    :aria-label="$t('breadcrumbs-aria', { ns: 'usermanagement' })"
                    :breadcrumbs="breadcrumbs"
                    dropdownUseRouterLinks
                    showDimText
                />
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <PageHeader title="All Educators" />
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow>
            <NebulaGridBlock :columns="12">
                <h2>Total number of users: {{ Object.keys(results).length }}</h2>
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow>
            <NebulaGridBlock :columns="12">
                <h2>Total number of active users: {{ active.length }}</h2>
                <h2>Total number of inactive users: {{ inactive.length }}</h2>
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow type="flex" class="browse-educators__filter-row">
            <NebulaVueSelect
                class="browse-educators__sort-dropdown"
                :clearable="false"
                :is-searchable="false"
                :options="options"
                placeholder="Sort by"
                size="s"
                @change-selected="handleSort"
            />
        </NebulaGridRow>
        <NebulaGridRow>
            <NebulaGridBlock :columns="12">
                <MemberTable :headers="['Name', 'Email', 'educatorId', 'schoolId (siteId)', 'Status']">
                    <tr v-for="(member, idx) in results" :key="idx" class="nebula-table__row">
                        <NebulaTableCell
                            responsiveLabel="Name"
                            class="nebula-table__cell browse-educators__name-cell browse-educators__table-cell"
                        >
                            <template #tableCell>
                                <ProfileAvatar class="browse-educators__member-avatar" :image="member.avatarUrl" size="xs" />
                                <div>
                                    <p class="browse-educators__member-name">{{ member.firstName }} {{ member.lastName }}</p>
                                </div>
                            </template>
                        </NebulaTableCell>
                        <NebulaTableCell responsiveLabel="Email" class="nebula-table__cell">
                            <template #tableCell>
                                {{ member.email }}
                            </template>
                        </NebulaTableCell>
                        <NebulaTableCell responsiveLabel="educatorId" class="nebula-table__cell">
                            <template #tableCell>
                                {{ member.educatorId }}
                            </template>
                        </NebulaTableCell>
                        <NebulaTableCell responsiveLabel="educatorId" class="nebula-table__cell">
                            <template #tableCell>
                                {{ member.schools[0].schoolId }}
                            </template>
                        </NebulaTableCell>
                        <NebulaTableCell responsiveLabel="Status" class="nebula-table__cell">
                            <template #tableCell>
                                <NebulaBadge size="s" :color="badgeColor(member.isActive)">{{ activeText(member.isActive) }}</NebulaBadge>
                            </template>
                        </NebulaTableCell>
                    </tr>
                </MemberTable>
            </NebulaGridBlock>
        </NebulaGridRow>
    </NebulaPageWrapper>
</template>

<script>
import {
    NebulaBadge,
    NebulaGrid,
    NebulaBreadcrumbs,
    NebulaVueSelect,
    NebulaTableCell,
} from '@discoveryedu/nebula-components';
import PageHeader from '@/components/shared/PageHeader.vue';
import createPayload from '@/mixins/data/createPayload';
import MemberTable from '@/components/shared/MemberTable.vue';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';

import scrollToTop from '@/mixins/scrollToTop';
import { browse } from '@/mixins/browse';
import axios from 'axios';

const API_DOMAIN = process.env.VUE_APP_API_URL;

export default {
    name: 'EducatorManagement',
    components: {
        NebulaBadge,
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaBreadcrumbs,
        NebulaTableCell,
        PageHeader,
        NebulaVueSelect,
        MemberTable,
        ProfileAvatar,
    },
    mixins: [createPayload, scrollToTop, browse],
    computed: {
        breadcrumbs() {
            const fallbackBreadcrumb = {
                dim_text: '',
                link: '/',
                text: 'Dashboard',
            };

            const storedBreadcrumbs = this.$store.state.app.breadcrumb;
            return storedBreadcrumbs.length > 1
                ? storedBreadcrumbs : [fallbackBreadcrumb].concat(storedBreadcrumbs);
        },
    },
    data() {
        return {
            results: [],
            displayedResults: [],
            inactive: [],
            active: [],
            loading: true,
            filters: {
                tag: [],
                industry: [],
                timeslot: [],
                language: [],
            },
            sort: 'alpha-by-last-name',
            sortConfig: {
                'alpha-by-last-name': {
                    order: 'ASC',
                    sort: 'last_name',
                },
                'reverse-alpha-by-last-name': {
                    order: 'DESC',
                    sort: 'last_name',
                },
            },
            options: [
                {
                    value: 'alpha-by-last-name',
                    label: 'Last Name (A to Z)',
                },
                {
                    value: 'reverse-alpha-by-last-name',
                    label: 'Last Name (Z to A)',
                },
            ],
        };
    },
    methods: {
        async getResults() {
            const params = this.getCombinedParams().toString();

            const payload = await this.createPayload({ params });

            try {
                const response = await axios.get(`${API_DOMAIN}/educators/all?${params}`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`, // send the access token through the 'Authorization' header
                    },
                });
                // this.$store.dispatch('updatePagination', response.data);
                this.results = response.data;

                const ids = Object.keys(this.results);

                ids.forEach((id) => {
                    const { isActive } = this.results[id];
                    const obj = this.results[id];

                    if (isActive) {
                        this.active.push(obj);
                    } else {
                        this.inactive.push(obj);
                    }
                });
            } catch (error) {
                console.log(payload);
                console.log(error);
            }

            this.loading = false;
        },
        badgeColor(isActive) {
            return isActive ? 'green' : 'light-gray';
        },
        activeText(isActive) {
            return isActive ? 'Active' : 'Inactive';
        },
    },
    async mounted() {
        this.scrollToTop();

        await this.getResults();
    },
};
</script>

<style lang="stylus">
.browse-educators {
    &__table-cell {
        padding: 10px;
    }
    &__member-name {
        margin: 0;
    }
    &__filter-row {
        gap: $nebula-space-2x;
        display: flex;
        align-items: center;
        margin-bottom: $nebula-space-4x;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            justify-content: flex-end;
            margin-bottom: $nebula-space-5x;
        }
    }

    &__filter-accordion {
        width: 265px;
        .nebula-checkbox__container {
            width: 100%;
        }
        .nebula-checkbox__text {
            line-height: 1em;
        }
    }

    &__sort-dropdown {
        --nebula-vs-dropdown-toggle-border: 1px solid $nebula-color-platform-interface-400;
        margin: 0;
        width: 100%;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            width: 235px;
        }
    }

    &__availability-accordion {
        .nebula-accordion__content-container {
            // --nebula-accordion-padding: 0;
            --nebula-date-picker-calendar-border: none;
        }
        .nebula-date-picker__calendar>div {
            width: 100%;
        }
    }

    &__datepicker {
        margin: -16px -16px 0 -16px;
        --nebula-date-picker-caret-size: 24px;
        --nebula-date-picker-calendar-date-border: 2px solid #ffffff;
        --nebula-icon-size-s: 12px;

        .nebula-date-picker__calendar {
            width: 100%;
            & .picker-view .vdp-datepicker__up {
                margin-left: 0;
            }
            .picker-view .cell {
                height: 33px;
            }
        }
        &:after {
            content: '';
            display: block;
            height: 1px;
            background: $nebula-color-platform-interface-300;
            margin-inline: $nebula-space-2x;
            margin-bottom: $nebula-space-4x;
        }
    }
}
</style>
